@import "../../base/index.scss";

.levelpan-container {
  padding-top: 15px;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  .level-controller {
    display: flex;
    padding: 0px 20px;
    align-items: center;
    margin: 5px 0px;
    position: relative;

    span {
      width: 90px;
      font-size: 14px;
      font-family: Nunito;
    }

    .checkmark-50 {
      position: absolute;
      z-index: 1;
      top: 0px;
      left: 162px;
      height: 100%;
      width: 0px;
      border: 1px solid $col-blue;
    }

    .checkmark-25 {
      @extend .checkmark-50;
      left: 143px;
    }
  }

  .level-container-title {
    height: 40px;
    color: $col-white;
    margin-top: 10px;
    border-radius: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(23.88deg, #2a87b9 1.69%, #907bfd 96.1%);

    h4 {
      margin: 0px;
      text-transform: capitalize;
      font-family: Nunito;
      font-size: 15px;
    }
  }
}
