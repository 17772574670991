.MuiDrawer-paper{
    top: 0;
    flex: 1 0 auto;
    height: 100%;
    display: flex;
    outline: 0;
    z-index: 1200;
    position: fixed;
    overflow-y: visible !important;
    flex-direction: column;
}
.add-notes{
    position: absolute;
    bottom: 10px;
    right: 10px;
    cursor: pointer;
}
.all_note_visiable{
    display: flex;
    position: absolute;
    right: 50px;
}