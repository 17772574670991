iframe {
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  border: none;
}

.map-menu {
  z-index: 1304 !important;
  width: auto !important;
  top: 55px !important;
  left: -45px !important;
}

.backdrop{
  background-color:#000000;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 3 !important;
  opacity: 0.8;
  .center{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  p{
    color: #ffffff;
    font-size: 20px;
    padding-top: 30px;
  }
}