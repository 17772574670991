@import "../../base/index.scss";

.bubble-container {
  display: flex;
  align-items: flex-end;
  padding: 10px;

  .MuiAvatar-root {
    text-transform: capitalize;
  }

  .bubble {
    flex: 1;
    position: relative;
    background-color: $col-light-sky;
    padding: 9px 13px;
    font-size: 12px;
    margin-right: 15px;
    border-radius: 6px;
    box-shadow: 0px 2px 3px rgba($color: $col-black, $alpha: 0.3);

    span {
      font-weight: 800;
      margin-right: 5px;
    }

    &::after {
      content: "";
      position: absolute;
      right: -6px;
      bottom: 6px;
      width: 15px;
      height: 15px;
      background-color: $col-light-sky;
      transform: rotate(45deg);
      box-shadow: 2px -1px 2px rgba($color: $col-black, $alpha: 0.2);
      clear: both;
    }
  }

  .avatar {
    width: 28px;
    height: 28px;
    border-radius: 50%;
  }
}

.timestamp {
  font-size: 12px;
  margin-left: 10px;
  margin-top: 5px;
  text-align: center;
}
