@import "../../base/index.scss";

.feedback-item {
  display: flex;
  height: 60px;
  min-height: 60px;
  align-items: center;
  background-color: #ffffff;
  border-bottom: 1px solid #e4e4e4;
  border-top: 1px solid #e4e4e4;

  &-number {
    font-size: 15px;
    line-height: 20px;
    width: 40px;
    padding: 14px 0px;
    color: #4f4f4f;
    border-right: 1px solid #e4e4e4;
    text-align: center;
  }

  &-body {
    flex: 1;
    padding: 10px;

    .feedback-loop-name {
      margin: 0;
      font-size: 15px;
      color: #4f4f4f;
      line-height: 20px;
      white-space: nowrap;
      text-overflow: ellipsis;
      width: 180px;
      overflow: hidden;
    }

    .feedback-loop-type {
      margin: 0;
      color: #6e767f;
      font-size: 12px;
      line-height: 16px;
    }
  }

  &-menu {
    border-left: 1px solid #e4e4e4;
  }

  &-action {
    display: flex;
  }

  &-button {
    background-color: transparent;
    border: none;
    width: 38px;
    outline: none !important;
    cursor: pointer;
  }

  &-selected {
    @extend .feedback-item;
    background-color: #ecf8fc;
  }

  // .dropbtn {
  //   color: white;
  //   padding: 16px 16px 16px 5px;
  //   font-size: 11px;
  //   border: none;
  //   cursor: pointer;
  //   width: auto;
  // }
  
  // .dropdown {
  //   position: relative;
  //   display: inline-block;

  //   .dropdown-content {
  //     display: none;
  //     // position: absolute;
  //     right: 0;
  //     background-color: #F8F8FA;
  //     width: 175px;
  //     box-shadow: 0px 2px 4px rgba(50, 50, 50, 0.3);
  //     border-radius: 4px;
  //     padding: 7px 0px;
  //     // z-index: 1;
  //     margin: -15px 5px 0px 22px;

  //     span{
  //       display: flex;
  //       padding: 2px 8px 2px 8px;
  //       a {
  //         width: 100%;
  //         color: #384360;
  //         text-decoration: none;
  //         display: block;
  //         font-size: 13px;
  //         font-family: sans-serif;
  //       }
  //       img{
  //         padding-left: 10px;
  //         width: 20px;
  //       }
  //     }
  //   }
  //   &:hover .dropdown-content{
  //     display: block;
  //   }
    // .show{
    //   display: block;
    // }
  // }
  .dot {
    height: 15px;
    width: 15px;
    background-color: #bbb;
    border-radius: 50%;
    margin-left: 10px;
  }
  .tooltip {
    position: relative;
    display: inline-block;
  }

  .tooltip .tooltiptext {
    visibility: none;
    width: 800%;
    background-color: #4c5a6b;
    color: #fff;
    text-align: center;
    border-radius: 4px;
    padding: 10px 20px;
    font-family: "Open Sans";
    /* Position the tooltip */
    position: absolute;
    z-index: 1;
    top: 150%;
    left: -10px;
    margin-left: -60px;
    &:after {
      content: "";
      position: absolute;
      display: block;
      width: 10px;
      height: 10px;
      transform-origin: 50% 50%;
      transform: rotate(45deg) translateX(-50%);
      background-color: #4c5a6b;
      left: 50%;
      top: -1px;
      z-index: 400;
    }
    &:hover{
      visibility: visible;
    }
  }
}
