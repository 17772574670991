body {
  margin: 0;
  font-family: "Nunito", BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
  overscroll-behavior: none;

  // div[data-appzi-dom="1"]{
  //   z-index: 2 !important;
  //   display: none;
  //   div[data-appzi-dom="1"]{
  //     display: block;
  //   }
  // }
}

#root {
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.offline-error {
  position: fixed;
  bottom: 5rem;
  left: 50%;
  transform: translate(-50%, 0px);
  padding: 0.5rem 1.5rem;
  box-shadow: 0px 5px 5px rgba($color: #000000, $alpha: 0.3);
  z-index: 99999;
  border-radius: 0.3rem;
  background-color: #d96351;
  color: #ffffff;
  display: flex;
  align-items: center;

  div {
    cursor: pointer;
    margin-left: 0.5rem;
    padding: 0.5rem;
    border-radius: 0.3rem;
    font-size: 10px;
    background-color: #bb5544;
  }
}
