@import "../base/index.scss";
.solid {
    border-bottom: 1px solid #bbb;
    width: 100%;
    display: flex;
    span {
        font-size: 14px;
        margin: 18px 85px 0 80px;
        line-height: 2;
        font-family: 'Open Sans';
    }
    .invite-title {
        color: #50A896;
        font-weight: bold;
        border-bottom: 4px solid #50A896;
        cursor: pointer;
    }
    .mr-3 {
        margin-right: 20px;
    }
    .ml-3 {
        margin-left: 20px;
    }
}

.invite-dlg {
    padding: 25px 25px 10px 25px;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    .MuiInputBase-input {
        font-size: 14px;
    }
    .signIn {
        color: #50A896;
        font-weight: bold;
        cursor: pointer
    }
    h3 {
        text-align: center;
        font-size: 14px;
        margin-bottom: 10px;
    }
    .input-form {
        margin-top: 0px;
        // width: 90%;
        margin-left: auto;
        margin-right: auto;
        display: flex;
    }
    .button-container-div {
        text-align: center;
    }
    .forgot {
        color: #50A896;
        cursor: pointer;
        width: 100%;
        justify-content: end;
        text-transform: capitalize;
        font-size: 14px;
    }
    .btn-invite {
        width: fit-content;
        padding: 16px 16px;
        margin-top: 14px;
        margin-bottom: 14px !important;
        margin-bottom: 0px;
        background-color: $col-green;
        border-radius: 10px;
        .MuiButton-label {
            color: $col-white;
            font-weight: bold;
            line-height: 1;
            font-style: normal;
            text-transform: initial;
            font-size: 14px;
            font-family: "Open Sans";
        }
    }
    .MuiFormLabel-root {
        font-size: 14px;
    }
}