// .confirm-dlg {
//     .MuiDialog-paper {
//         width: 500px;
//         p {
//             padding-left: 24px;
//             padding-right: 24px;
//         }
//     }
// }

.note-dlg {
    text-align: center;
    &__content {
        width: 400px;
        padding: 0px 30px;
    }
    .MuiDialog-paper {
        padding: 15px 0px;
    }
    .MuiDialogActions-spacing {
        display: flex;
        justify-content: center;
        button {
            background-color: #00ad96;
            color: #ffffff;
            width: 100px;
            text-transform: capitalize;
        }
    }
}