@import "../../base/index.scss";
.participant-container {
    padding: 5px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .avatar-container {
        display: flex;
        align-items: center;
        color: $col-white;
        font-size: $font-size-14;
        // text-transform: capitalize;
        .avatar-name {
            margin-left: 10px;
            font-family: "Open Sans";
        }
        .MuiBadge-dot {
            height: 15px;
            background: #7EC657;
            min-width: 15px;
            border-radius: 10px;
            border: solid 1px #384360;
            transform: scale(1) translate(-140%, -50%);
        }
    }
    .dropdown {
        top: 2px;
    }
    .btn-remove {
        color: $col-white;
        font-size: $font-size-12;
        display: none;
    }
    &:hover {
        background-color: rgba($color: $col-black, $alpha: 0.15);
        .btn-remove {
            display: block;
            text-transform: capitalize;
            font-weight: 300;
        }
    }
    // .confirm-dlg {
    //     .MuiPaper-root {
    //         width: 400px !important;
    //         p {
    //             padding-left: 24px;
    //             padding-right: 24px;
    //         }
    //     }
    // }

    // .green-modal {
    //     .MuiPaper-rounded {
    //         border-radius: 15px;
    //         .green-modal-header {
    //             background-color: #50A896;
    //             color: #fff;
    //             font-family: "Inter";
    //         }
    //         .green-modal-body {
    //             font-family: "Open Sans";
    //             font-size: 16px;
    //         }
    //         .green-modal-footer {
    //             padding-bottom: 24px;
    //             font-family: "Inter";
    //             .btn-outline {
    //                 font-size: 14px;
    //                 margin: 0 14px 0 0;
    //                 padding: 10px 15px;
    //                 border-radius: 5px;
    //             }
    //             .btn-green {
    //                 background-color: #50A896;
    //                 color: #fff;
    //                 font-size: 14px;
    //                 padding: 10px 15px;
    //                 border-radius: 5px;
    //             }
    //         }
    //     }
    // }
}

.color_picker {
    margin: 8px 0px 10px 11px;
    max-width: 180px;
    img {
        margin-left: 133px;
        margin-top: -25px;
        position: fixed;
        width: 25px;
        cursor: pointer;
    }
}

.chrome-picker {
    // width: auto !important;
    margin: 10px 10px;
    padding: 10px 10px;
    svg {
        display: none;
    }
}

.avt-grey {
    background-color: $col-grey-dark !important;
    color: $col-white !important;
    font-family: Nunito !important;
}

.avt-purple {
    background-color: $col-purple-avt !important;
    color: $col-white !important;
    font-family: Nunito !important;
}

.avt-yellow {
    background-color: $col-yellow !important;
    color: $col-white !important;
    font-family: Nunito !important;
}

.avt-orange {
    background-color: $col-orange !important;
    color: $col-white !important;
    font-family: Nunito !important;
}

.avt-blue {
    background-color: $col-blue !important;
    color: $col-white !important;
    font-family: Nunito !important;
}

.dropdown i {
    cursor: pointer;
}

.dropdown-content2 {
    display: none;
    position: absolute;
    background-color: #ffffff;
    min-width: 150px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    border-radius: 5px;
    margin: -29px 0px 0px 140px;
}

.dropdown-content2 div {
    cursor: pointer;
    color: black;
    padding-left: 10px;
    text-decoration: none;
    display: block;
    text-align: left;
    font-size: 15px;
    margin: 10px;
    cursor: pointer;
    font-family: 'Open Sans';
    font-weight: 400;
}

// .Observer:hover .dropdown-content2{
//   display: block;
// }
.show {
    display: block;
}

// .MuiDialog-paper {
//     width: auto;
// }