@import "../../base/index.scss";

.MuiDialog-paperFullScreen {
  border-radius: 0px !important;
}

.analysis-container {
  background: linear-gradient(60.19deg, #2a87b9 1.69%, #907bfd 96.1%);
  color: $col-white;
  padding-top: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  box-sizing: border-box;

  .analysis-header {
    display: flex;
    justify-content: center;
    width: 100%;
    min-height: 91px;
    box-sizing: border-box;
    align-items: center;
    padding: 20px 90px;
    position: relative;
    border-bottom: 1px solid rgba($color: $col-white, $alpha: 0.5);

    h1 {
      font-size: 36px;
      font-weight: 300;
      margin: 0px;
      line-height: 1;
      position: absolute;
      top: 30px;
      left: 90px;
    }

    &__levelpan {
      display: flex;
      flex-direction: column;
      align-items: center;

      p {
        font-size: 12px;
        font-family: Roboto;
        letter-spacing: 0.2em;
        text-transform: uppercase;
        margin: 0px;
        margin-bottom: 5px;
      }

      .levels-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 140px;

        .level {
          width: 31px;
          height: 31px;
          border-radius: 50%;
          border: none;
          outline: none;
          cursor: pointer;
          display: flex;
          font-size: 22px;
          align-items: center;
          justify-content: center;

          &-filled {
            background-color: $col-white;
            color: $col-blue;
          }

          &-outline {
            background-color: transparent;
            color: $col-white;
          }
        }
      }
    }

    .btn-close {
      position: absolute;
      right: 90px;
      color: $col-white;
    }
  }

  .analysis-body {
    width: 1263px;
    grid-template-columns: 7fr 5fr;
    padding: 50px 90px 0px 90px;
    height: calc(100% - 151px);

    .level-processbar-container {
      display: inline-block;
      flex-direction: column;
      width: 650px;
      height: 100%;
      overflow: auto;

      .level-title {
        font-size: 36px;
        line-height: 40px;
        font-weight: normal;
        margin: 0px;

        span {
          font-weight: bold;
        }
      }

      .level-desc {
        font-size: 22px;
        font-family: "Open Sans";
        font-style: italic;
        margin: 18px 0px;
        font-weight: 100;
      }

      .progressbar-container {
        display: flex;
        flex-direction: column;
        margin-top: 60px;

        .progressbar {
          display: grid;
          position: relative;
          width: 530px;
          grid-template-columns: 8fr 310px 2fr 2fr;
          align-items: center;

          &-name {
            font-size: 22px;
            font-weight: bold;
          }

          .checkmark-100 {
            display: none;
          }

          .checkmark-75 {
            position: absolute;
            z-index: 1;
            top: 0px;
            left: 357px;
            height: 100%;
            width: 0px;
            border: 1px solid $col-white;
          }

          .checkmark-50 {
            @extend .checkmark-75;
            left: 268px;
          }

          .checkmark-25 {
            @extend .checkmark-75;
            left: 207px;
          }
        }
      }

      .level-value {
        font-size: 14px;
      }

      .progressbar-helper {
        font-size: 17px;
        font-weight: 300;
        margin-top: 15px;
      }
    }

    .level-tips-container {
      display: inline-block;
      float: right;
      width: 520px;
      height: 100%;
      overflow: auto;

      .level-tips-title {
        font-size: 36px;
        margin: 0px;
        display: flex;
        align-items: center;
        margin-bottom: 40px;

        img {
          width: 42px;
          height: 42px;
          color: $col-white;
          margin-right: 10px;
        }
      }

      .tips-list {
        display: inline-block;
        font-family: "Open Sans";
        overflow: hidden;

        .tip-item {
          border-bottom: 1px solid $col-white;

          h4 {
            font-size: 20px;
            margin: 30px 0px;
          }

          p {
            font-size: 18px;
            margin: 30px 0px;
            font-weight: 100;
          }
        }
      }
    }
  }
}
