@import "../../base/index.scss";
.zoom-toolbar__container {
    position: absolute;
    bottom: 20px;
    left: 20px;
    display: flex;
    // z-index: 1209;
    align-items: center;
    background-color: $col-white;
    border-radius: 4px;
    box-shadow: 0px 1px 3px rgba($color: $col-grey-dark, $alpha: 0.5);
    width: 135px;
}

.btn {
    border: none;
    font-size: $font-size-12;
    background-color: transparent;
    color: $col-btn;
    outline: none;
    cursor: pointer;
    &-center {
        display: flex;
        align-items: center;
        padding: 0px 9px;
        height: 30px;
        border-right: 1px solid $col-grey !important;
        img {
            margin-right: 4px;
            width: 15px;
        }
    }
    &-icon {
        font-size: $font-size-16;
        height: 30px;
        display: flex;
        align-items: center;
        padding: 0px 9px;
    }
    &:hover {
        color: $col-btn-hover;
    }
}

.zoom-label {
    // margin: 0px 4px;
    text-align: center;
    width: 100%;
    font-size: $font-size-12;
}

// .visibility-popover {
//     .MuiPopover-paper {
//         transform: translate(0px, -15px) !important;
//         .visibility-container {
//             padding: 10px;
//             display: flex;
//             flex-direction: column;
//             .visibility-item {
//                 justify-content: flex-start;
//                 text-transform: capitalize;
//                 font-family: "Open Sans";
//                 font-weight: 100;
//                 font-size: 12px;
//                 img {
//                     margin-right: 10px;
//                     width: 17px;
//                 }
//             }
//         }
//     }
// }