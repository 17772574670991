$col-white: #ffffff;
$col-black: #000000;
$col-grey: #e4e4e4;
$col-grey-light: #eaeaea;
$col-grey-dark: #d2d2d2;
$col-light-green: #d2ecea;
$col-light-sky: #ecf8fc;
$col-green: #18ab95;
$col-green-welcome: #d7ebea;
$col-green-dark: #397c79;
$col-blue: #009fd7;
$col-purple: #384360;
$col-purple-avt: #7c77b9;
$col-yellow: #ffeb3b;
$col-orange: #ff8a5b;

$col-txt-primary: #6e767f;
$col-txt-blue: #3f51b5;
$col-txt-dark: #4f4f4f;
$col-bg-primary: #f4f4f4;
$col-bg-chat: #f5f5f5;
$col-bg-main-title: #384360;

$col-btn: #c0c3c6;
$col-btn-hover: #9ea4aa;

$font-size-12: 12px;
$font-size-14: 14px;
$font-size-15: 15px;
$font-size-16: 16px;
$font-size-17: 17px;
$font-size-18: 18px;
$font-size-20: 20px;
$font-size-24: 24px;
$font-size-28: 28px;
