@import "../../base/index.scss";

.MuiDialog-paperWidthSm {
  max-width: none !important;
  border-radius: 15px !important;
  overflow: hidden;

  .MuiDialogContent-root {
    padding: 0px !important;
  }
}
.MuiDialog-paper {
  margin: 32px;
  position: relative;
  overflow-y: visible !important;
  overflow-x: visible !important;
}
.modal-last-line{
  text-align: center;
  color: white;
  font-size: 15px;
  left: 0px;
  margin-top: 20px;
  position: absolute;
  bottom: -40px;
  right: 0;
}
.header {
  width: 800px;
  background-color: $col-blue;
  display: flex;
  padding: 0px 20px 0px 30px;
  box-sizing: border-box;
  justify-content: space-between;
  border-radius: 15px 15px 0px 0px;
  h1 {
    color: $col-white;
  }

  .actions-group {
    display: flex;

    .MuiSvgIcon-fontSizeLarge {
      color: $col-white;
    }
  }
}

.content {
  width: 800px;
  box-sizing: border-box;
  padding: 30px;
  display: flex;
  flex-direction: column;

  #standard-measure{
    color: #000;
  }
  #standard-name{
    color: #000;
  }
  .MuiFormControl-root {
    margin-bottom: 30px;
  }

  .MuiTextField-root{
    .Mui-focused{
      color: $col-blue;
    }
    .MuiInput-underline:after{
      border-bottom: 2px solid $col-blue;
    }
  } 
  .lab-note {
    margin-bottom: 10px;
    font-size: $font-size-14;
    color: $col-txt-primary;
  }

  .first{
    .txt-area-note {
      height: 90px;
      border-top:1px solid #ccc !important;
      .ql-container{

        /* width */
        ::-webkit-scrollbar {
          width: 7px;
        }

        /* Track */
        ::-webkit-scrollbar-track {
          box-shadow: inset 0 0 5px grey; 
          border-radius: 10px;
          background-color:
          rgba(51, 51, 51, 0.20);
        }
        
        /* Handle */
        ::-webkit-scrollbar-thumb {
          background: rgba(51, 51, 51, 0.5); 
          border-radius: 10px;
        }
        .ql-editor{
          &::before{
            font-style: normal;
          }
        }
      }
    }
    .ql-toolbar{
      display: none;
    }
  }
  .txt-area-note {
    height: 300px;
  }

  .textfield_font{
    position: absolute;
    right: 30px;
    top: 140px;
    font-weight: bold;
  }
  .fontColor{
    color: $col-blue;
  }
  .testfiel{
    position: relative;
    cursor: pointer;
    .updtae_note{
      width: 100%;
      margin-bottom: 0px;
    }
    img{
      display: none;
      position: absolute;
      left: calc(50% - 16px) !important;
      top: calc(50% - 17px) !important;
    }
    &:hover{
      background-color: rgba($color:$col-black, $alpha: 0.5);
      img{
        display: block;
      }
    }
  }
}

.actions {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  padding-top: 0px;
  padding-bottom: 20px;

  .add_note{
    background-color: #F5F5F5;
  }
  button {
    margin: 0px 20px;
    height: 50px;
    img {
      margin-right: 10px;
    }
  }

  .cancle{
    color: $col-blue;
    margin: 0 !important;
  }
  .MuiFab-root {
    background-color: $col-blue;
    color: $col-white;
    // width: 120px;
    height: 50px;
    border-radius: 5px;
    box-shadow: none;
    &:hover {
      background-color: $col-blue;
    }
  }
}

// .my-editing-area:hover {
//   border: 2px solid #000000 !important;
//   border-top: none !important;
// }

// .my-editing-area:focus-within {
//   border: 2px solid rgb(63, 81, 181) !important;
//   border-top: none !important;
// }
