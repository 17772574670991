#basic-menu{
    .MuiMenu-paper{
        box-shadow: 0px 2px 4px rgba(50, 50, 50, 0.3);
        border-radius: 4px;
        position: absolute;
        top:80px !important;
        right: 20px;
        left: auto !important;
        width: 170px;
        ul{
            padding: 8px 0px;
            li{
                color:#333333;
                padding: 8px 20px 8px 17px;
                font-size: 15px;
                a{
                    text-decoration: none;
                    color:#333333;
                }
            }
        }
        .MuiList-padding{
            padding-bottom: 0px;
        }
    }
}
.link-dailog-box{
    z-index: 1303 !important;
    .MuiPaper-root{
        border-radius: 0px;
    }
    .dailog{
        padding: 0%;
    }
    .dailog-btn {
        margin: 10px 30px 30px 30px;
        .close-btn {
          border: 1px solid #333333;
          color: black;
          padding: 10px;
          text-transform: none;
          font-weight: 400;
        }
    }
}


.post-content{
    margin-top: 35px;
    .fusion-fullwidth{
        border-style: none !important;
    }
}
.entry-title{
    // font-size:30px;
}
.avada-page-titlebar-wrapper{
    .fusion-page-title-bar{
        height: 120px;
    }
}

.fusion-page-title-bar .fusion-page-title-row h1 { 
    font-size: 35px !important;
}