@import "../base/index.scss";

.login-container {
  background-color: $col-purple;
  height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .login-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 30px 60px;
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    box-sizing: border-box;

    .btn-group {
      display: flex;
      align-items: center;

      .MuiButton-label {
        color: $col-white;
        font-weight: normal;
        line-height: 1;
        text-transform: capitalize;
      }

      .btn-signup {
        background-color: $col-green;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0px;
        height: 30px;
        width: 145px;
        border-radius: 15px;
        margin-left: 20px;
      }
    }

    @media (max-width: 768px) {
      padding: 20px 10px;

      img {
        display: none;
      }
    }
  }

  .login-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: $col-white;
    border-radius: 10px;
    padding: 40px 60px;
    text-align: center;
    position: relative;
    .btn-close{
      position: absolute;
      width: auto;
      right: 5px;
      left: auto;
      top: 5px;
      bottom: auto;  
    }
    .login-card-header {
      margin: 0px;
      font-weight: 800;
      margin-bottom: 20px;
      color: $col-purple;
    }

    .disabled-message {
      color: #f44336;

      a {
        cursor: pointer;
        color: rgba(0, 0, 0, 0.54);
      }
    }

    .input-form {
      margin-top: 30px;
      width: 100%;
      min-width: 230px;
    }

    .btn-forgot {
      margin-top: 5px;
      text-align: left;
      margin-bottom: 30px;
      cursor: pointer;
      font-size: 13px;
      color: $col-txt-primary;
      width: 100%;
    }

    .btn-login {
      background-color: $col-green;
      color: $col-white;
      width: fit-content;
      margin-top: 40px;
      padding: 10px 40px;

      .MuiButton-label {
        color: $col-white;
        font-weight: normal;
        line-height: 1;
        text-transform: capitalize;
        font-size: 18px;
      }
    }
    
    .btn-signup {
       background-color: $col-green ;
      color: $col-white;
      width: fit-content;
      margin-top: 40px;
      padding: 10px 40px;

      .MuiButton-label {
        color: $col-white;
        font-weight: normal;
        line-height: 1;
        text-transform: initial;
        font-size: 18px;
      }
    }

    .btn-redirect {
      font-size: 14px;
      color: $col-txt-primary;
      margin-top: 20px;

      button {
        color: $col-green;
        font-weight: 800;
        cursor: pointer;
        text-transform: capitalize;
      }
    }
  }

  .not-support-header {
    font-size: 32px;
    font-weight: bold;
    line-height: 44px;
    text-align: center;
    padding-left: 10px;
    padding-right: 10px;
    box-sizing: border-box;
    color: #ffffff;
  }

  .not-support-desc {
    font-size: 18px;
    line-height: 25px;
    text-align: center;
    padding-left: 10px;
    padding-right: 10px;
    box-sizing: border-box;
    max-width: 391px;
    color: #ffffff;
  }
}

.terms-link-btn {
  color: $col-purple !important;
}
