.dailog-box {
  width: 620px;
  margin: auto;
  .MuiPaper-root{
    padding: 11px 11px 0px 11px;
    border-radius: 10px !important;
  }
  .dailog {
    margin: 0px 30px 10px 25px;
    .dailog-p {
      margin: 0;
      font-family: "Open Sans";
      font-style: italic;
      font-weight: 300;
      padding: 5px;
    }
    .dailog-h1{
      text-align: center;
      margin: 20px 0 0 0;
      font-family: "Open Sans";
      font-size: 22px;
    }
    .text-background {
      background-color: #fdf9eb;
      display: flex;
      padding: 5px;
    }
    p{
      margin: 0;
      color: #333333;
    }
    // .crud-modal-text {
      // font-family: 'Inter';
      // font-size: 17px;
      // font-weight: 600;
      // line-height: 21px;
      // letter-spacing: 0em;
      // font-style: italic;
    // }
  }
  .dailog-plan {
    margin: 0px 30px 10px 25px;
    .dailog-p {
      margin: 0;
      font-family: "Open Sans";
      font-style: italic;
      font-weight: 300;
      padding: 5px;
    }
  }
  .dailog-btn {
    display: flex;
    justify-content: center;
    margin: 10px 30px 30px 25px;
    .close-btn {
      border: 1px solid #333333;
      color: black;
      padding: 10px;
      text-transform: none;
      font-weight: 400;
      height: 40px;
    }
    .green-btn {
      background-color: #50a896;
      margin-right: 10px;
      color: white;
      padding: 10px;
      text-transform: none;
      font-weight: 400;
      height: 40px;
      width: 150px;
    }
  }
  .space{
    margin-bottom: 15px;
  }
}
.headding-text {
  color: #50a896;
  font-weight: bold;
}