.note{
  background-color: #fff;
  bottom: 50%;
  left: 5%;
  position: relative;
  width: 190px;
  border-radius: 10px;
  box-shadow: 0px 4px 4px 0px #00000040;
  margin-bottom: 10px;
  .note-header{
    text-align: end;
    padding: 8px 8px 0px 5px;
    display: none;
    img{
      width: 15px;
      padding-left: 5px;
      cursor: pointer;
    }
  }
  // &:hover{
    .note-header{
      display: block;
    }
    // .note-container{
    //   padding: 0px 20px 15px 20px;
    // }
  // }
  .note-container{
    padding: 10px 20px 15px 20px;
    span{
      margin: 0;
      font-size: 14px;
      line-break: anywhere;
    }
    input{
      width: 95%;
      border: 0;
      height: auto;
      font-size: 14px;
      &:focus-visible {
        outline: none;
      }
    }
  }
}